// ************
// Header
// ************

header {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  position: relative;
}
// header {
//   position: fixed;
//   z-index: 2;
//   width: 100%;
// }

.logo {
  max-width: 75px;
  display: inline-block;
}

.nav {
  position: fixed;
  background: $clr-dark;
  color: $clr-light;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  transform: translateX(100%);
  transition: transform 0.9s cubic-bezier(0.5, 0, 0.5, 1);
}

.nav__list {
  list-style: none;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 0;
  padding: 0;
}

.nav__link {
  color: inherit;
  font-weight: $fw-bold;
  font-size: $fs-h2;
  text-decoration: none;
}

.nav__link:hover {
  color: $clr-accent;
}

.nav-toggle {
  padding: 0.5em;
  background: transparent;
  border: 0;
  cursor: pointer;
  position: absolute;
  right: 1em;
  top: 1em;
  z-index: 1000;
}

.nav-open .nav {
  transform: translateX(0);
}

.nav-open .nav-toggle {
  position: fixed;
}

.nav-open .hamburger {
  transform: rotate(0.625turn);
}

.nav-open .hamburger::before {
  transform: rotate(90deg) translateX(-6px);
}

.nav-open .hamburger::after {
  opacity: 0;
}

.hamburger {
  display: block;
  position: relative;
}

.hamburger,
.hamburger::before,
.hamburger::after {
  background: $clr-accent;
  width: 2em;
  height: 3px;
  border-radius: 1em;
  transition: transform 250ms ease-in-out;
}

.hamburger::before,
.hamburger::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
}

.hamburger::before {
  top: 6px;
}
.hamburger::after {
  bottom: 6px;
}
