.spinner {
  display: grid;
  margin: 2rem 0 0 4rem;
  grid-auto-flow: column;
  justify-items: center;
  align-items: center;
  transform: translate(-50%, -50%);
}

.spinner > div {
  width: 15px;
  height: 15px;
  background-color: $clr-accent;
  border-radius: 100%;
  animation: bounce 1.4s infinite ease-in-out both;
  -webkit-animation: bounce 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  animation-delay: -0.32s;
  -webkit-animation-delay: -0.32s;
}

.spinner .bounce2 {
  animation-delay: -0.16s;
  -webkit-animation-delay: -0.16s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
