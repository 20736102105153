// *******************
// mixins
// *******************

@mixin easeOut {
  transition: all 0.5s ease-out;
}

@mixin background {
  @if $show-home-image {
    &#bg-img {
      background: $home-image;
      background-attachment: fixed;
      background-size: cover;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: rgba($primary-color, $background-opacity);
      }
    }
  }
}

// *******************
// Set Text Color
// *******************

@function set-text-color($color) {
  @if (lightness($color) > 40) {
    @return #000;
  } @else {
    @return #fff;
  }
}

// *******************
// media query mixins
// *******************

@mixin mediaSm {
  @media screen and (max-width: 500px) {
    @content;
  }
}

@mixin mediaMd {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin mediaLg {
  @media screen and (min-width: 769px) and (max-width: 1170px) {
    @content;
  }
}

@mixin mediaXl {
  @media screen and (min-width: 1171px) {
    @content;
  }
}
