// ************
// Menu
// ************
.menu {
  z-index: 2;
}
.menu-btn {
  position: fixed;
  z-index: 80;
  right: 1em;
  top: 1em;
  cursor: pointer;
  @include easeOut;

  .btn-line {
    width: 28px;
    height: 3px;
    margin: 0 0 5px 0;
    background: $clr-accent;
    @include easeOut;
  }

  // Rotate Into X With Menu Lines
  &.close {
    transform: rotate(180deg);

    .btn-line {
      // Line 1 - Rotate
      &:nth-child(1) {
        transform: rotate(45deg) translate(5px, 5px);
      }

      // Line 2 - Hide
      &:nth-child(2) {
        opacity: 0;
      }

      // Line 3 - Rotate
      &:nth-child(3) {
        transform: rotate(-45deg) translate(7px, -6px);
      }
    }
  }
}

// Menu Overlay
.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  visibility: hidden;

  &.show {
    visibility: visible;
  }

  &-branding,
  &-nav {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    float: left;
    width: 50%;
    height: 100vh;
    overflow: hidden;
  }
  

  &-nav {
    margin: 0;
    padding: 0;
    background: darken($primary-color, 5);
    list-style: none;
    transform: translate3d(100%, 0, 0);
    @include easeOut;

    &.show {
      // Slide in from right
      transform: translate3d(0, 0, 0);
    }
  }

  // Branding Side
  &-branding {
    background: $primary-color;
    transform: translate3d(-100%, 0, 0);
    @include easeOut;

    &.show {
      // Slide in from left
      transform: translate3d(0, 0, 0);
    }

    .portrait {
      width: 175px;
      height: 250px;
      background: url('../assets/resumeImg.png');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 5px;

      &:hover {
        outline: 3px solid $clr-accent;
        opacity: 0.95;
      }
    }
    .social-list {
      list-style: none;
      display: flex;
      justify-content: center;
      margin: 0.5em 0 0;
      padding: 0;
    }

    .social-list__item {
      margin: 0 0.5em;
    }

    .social-list__link {
      padding: 0.5em;
      font-size: $fs-h3;
      color: $clr-light;
      &:hover {
        color: $clr-accent;
      }
    }
  }

  .nav__item {
    transform: translate3d(600px, 0, 0);
    @include easeOut;

    &.show {
      // Slide in from right
      transform: translate3d(0, 0, 0);
    }

    &.current > a {
      color: $secondary-color;
    }
  }

  .nav-link {
    display: inline-block;
    position: relative;
    font-weight: $fw-bold;
    font-size: $fs-h3;
    text-transform: uppercase;
    padding: 1rem 0;
    color: set-text-color($primary-color);
    text-decoration: none;

    &__email {
      text-transform: lowercase;
      padding: 2.5rem 0 0 0;
    }
    &__resume {
      padding-bottom: 0.5rem;
      pointer-events: none;
    }

    &:hover {
      color: $secondary-color;
      opacity: 0.7;
      text-decoration: underline;
    }
  }
}

// Delay each nav item slide by 0.1s
@for $x from 1 through 4 {
  .nav-item:nth-child(#{$x}) {
    transition-delay: $x * 0.1s;
  }
}
