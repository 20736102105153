// *******************
// media queries
// *******************

@media (min-width: 600px) {
  .intro {
    display: grid;
    width: min-content;
    margin: 0 auto;
    grid-column-gap: 1em;
    grid-template-areas:
      'img title'
      'img subtitle'
      'img . '
      'counter counter';
    grid-template-columns: min-content max-content;
    padding-bottom: 0;
  }

  .item-links {
    margin: 0 auto;
    max-width: 622px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
  }
  .img-wrapper {
    width: 120%;
    grid-area: img;
  }
  .intro__img {
    grid-area: img;
    width: 100%;
    min-width: 250px;
    position: relative;
  }

  .section__title--intro {
    outline: none;
    text-align: left;
    margin-left: 3rem;
  }

  .section__subtitle--intro {
    align-self: start;
    grid-column: -1 / 1;
    grid-row: 2;
    text-align: right;
    position: relative;
    // left: -1.5em;
    width: calc(100% + 1.5em);
  }

  .about-me {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-template-areas:
      'title img  '
      'subtitle  img'
      'text img  ';

    grid-column-gap: 2em;
  }

  .section__title--about {
    grid-area: title;
    text-align: left;
  }

  .section__subtitle--about {
    text-align: left;
    grid-column: 1 / -1;
    grid-row: 2;
    position: relative;
    left: -1em;
    width: calc(100% + 2em);
    padding-left: 1em;
    padding-right: calc(200px + 4em);
  }

  .about-me__img {
    grid-area: img;
    position: relative;
  }
  .slider {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 800px) {
  h1 {
    font-size: 4.5rem;
  }
  h2 {
    font-size: 3.75rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  body {
    font-size: 1.125rem;
  }
  .logo {
    max-width: 6rem;
  }
  .skills {
    flex-direction: row;
    display: flex;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }

  .skill {
    margin-bottom: none;
  }
  .portfolio-item__subheader {
    text-align: left;
  }

  .skill + .skill {
    margin-left: 2em;
  }

  .section__subtitle--intro {
    left: -1.5em;
  }
  .slider-image {
    height: 30rem;
  }
  .error-page .error-header {
    outline: none;
    font-size: 15em;
  }
}
@media (min-width: 1200px) {
  .slider-image {
    box-shadow: $bs;
  }
  .slider-navButton::after {
    background: $clr-accent;
  }
}
// Widescreens
@include mediaXl {
  .projects {
    grid-template-columns: repeat(4, 1fr);
  }
}

// Desktops & Laptops
@include mediaLg {
  .projects {
    grid-template-columns: repeat(3, 1fr);
  }
}

// Tablets & Small Laptops
@include mediaMd {
  main {
    align-items: center;
    text-align: center;

    .lg-heading {
      line-height: 1;
      margin-bottom: 1rem;
    }
  }

  ul.menu-nav,
  div.menu-branding {
    float: none;
    width: 100%;
    min-height: 0;

    &.show {
      transform: translate3d(0, 0, 0);
    }
  }

  .menu-nav {
    height: 65vh;
    transform: translate3d(-100%, 0, 0);
    font-size: 24px;
    padding-top: 3rem;
    justify-content: flex-start;
  }

  .menu-branding {
    display: flex;
    flex-direction: column;
    height: 35vh;
    transform: translate3d(100%, 0, 0);

    .portrait {
      display: none;
    }
    .nav-link__resume {
      padding: 0;
      pointer-events: all;
    }
    .nav-link__email {
      padding-top: 1rem;
    }
  }

  .about-info {
    grid-template-areas:
      'bioimage'
      'bio'
      'job1'
      'job2'
      'job3';

    grid-template-columns: 1fr;
  }

  .projects {
    grid-template-columns: repeat(2, 1fr);
  }
}

// Smartphones
@include mediaSm {
  main {
    padding: 2rem;
    #home h1 {
      margin-top: 10vh;
    }

    .lg-heading {
      margin-top: 1rem;
      font-size: 5rem;
    }
  }

  .projects {
    grid-template-columns: 1fr;
  }
}
