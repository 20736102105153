// ************
// Intro
// ************

#home {
  padding: 0em 2em 5em 2em;
}
.intro {
  min-height: 500px;
  position: relative;
  padding-bottom: 2rem;

  &__portfolio {
    min-height: auto;
  }
}

strong {
  font-weight: $fw-bold;
}
.section__counter {
  height: 100px;
  display: grid;
  justify-items: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: $fw-bold;
  grid-area: counter;
  & strong {
    color: $clr-accent;
    font-size: 1.5rem;
    margin: 0 0.1rem;
  }
  &--logo {
    width: 12%;
    & img {
      width: 100%;
    }
  }
}

.img-wrapper {
  display: grid;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.intro__img {
  width: 100%;
  box-shadow: $bs;
}

.section__subtitle--intro {
  display: inline-block;
}

// ************
// Title Styles
// ************

.section__title--intro {
  text-align: center;
  font-weight: $fw-reg;
}
.section__title--intro strong {
  display: block;
}

.section__subtitle {
  margin: 0;
  font-size: $fs-h3;
}

.section__subtitle--intro,
.section__subtitle--about {
  text-align: center;
  width: 100%;
  background: $clr-accent;
  padding: 0.25em 1em;
  font-family: $ff-secondary;
  margin-bottom: 1em;
}

.section__subtitle--work {
  color: $clr-accent;
  font-weight: $fw-bold;
  margin-bottom: 2em;
}
