// ************
// Variables
// ************

@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@400;700;900&display=swap');

$primary-color: #111;
$secondary-color: #00aeef;
$clr-light: #fff;
$clr-dark: #303030;
$clr-accent: #00aeef;

$ff-primary: 'Alegreya Sans', sans-serif;
$ff-secondary: 'Alegreya Sans', sans-serif;

$background-opacity: 0.9;

$fw-reg: 300;
$fw-bold: 700;

$bs: 0.25em 0.25em 0.75em rgba(0, 0, 0, 0.25),
  0.125em 0.125em 0.25em rgba(0, 0, 0, 0.15);

$show-home-image: true;
$home-image: url(../img/background.jpg);

$fs-body: 1rem;
$fs-h1: 3rem;
$fs-h2: 2.25rem;
$fs-h3: 1.5rem;

// ************
// General Styles
// ************

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background: $clr-light;
  color: $clr-dark;
  margin: 0;
  font-family: $ff-primary;
  font-size: $fs-body;
  line-height: 1.6;
}

ul {
  list-style: none;
}

section {
  padding: 5em 2em;
}

#home {
  padding: 0em 2em 5em 2em;
}

img {
  display: block;
  max-width: 100%;
}

strong {
  font-weight: $fw-bold;
}

// ************
// Buttons
// ************

.btn {
  display: inline-block;
  padding: 0.5em 2.5em;
  background: $clr-accent;
  color: $clr-dark;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: $fw-bold;
  transition: transform 200ms ease-in-out;
}

.btn:hover {
  transform: scale(1.1);
}

// ************
// Typography
// ************

h1,
h2,
h3 {
  line-height: 1;
  margin: 0;
}

h1 {
  font-size: $fs-h1;
}

h2 {
  font-size: $fs-h2;
}
h3 {
  font-size: $fs-h3;
}

// *************
// General Styles
// *************
.section__title {
  margin: 1rem 0 0.25em 0;
}
