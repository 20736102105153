// ************
// Portfolio
// ************

.portfolio {
  text-align: center;
  background: $clr-dark;
  color: $clr-light;
  padding: 5em 2em;
}

.portfolio-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  width: 100%;
}

.portfolio-item {
  background: $clr-accent;

  position: relative;
  overflow: hidden;
  width: 100%;

  .portfolio-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  figcaption {
    position: absolute;
    top: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 1em;
    background: $clr-accent;
    opacity: 0.9;
    color: $clr-light;
    text-align: left;
    display: flex;
    flex-direction: column;
    transition: top ease-in-out 250ms;
  }

  &:hover figcaption,
  &:focus figcaption {
    top: 0;
  }
}

.portfolio-title {
  color: $clr-light;
  font-size: 2.5rem;
  margin-bottom: auto;
}

// .portfolio-desc {
//   // display: none;
// }

.portfolio-link {
  color: white;
  font-weight: $fw-bold;
  font-size: 1.1rem;
  text-decoration: none;
  border-bottom: 3px solid $clr-light;
  align-self: flex-start;

  &::after {
    content: url(../assets/arrow-white.svg);
    display: inline-block;
    height: 0.8em;
    width: 0.8em;
    margin-left: 0.5em;
  }
}

/* Individual portfolio item styles */
.item-links {
  text-align: center;
  padding: 0rem 0 4rem 0;
}

.item-links i {
  margin-right: 0.4rem;
}

.portfolio-item__subheader {
  text-align: center;
  font-size: $fs-h3;
  font-weight: bold;
  margin-bottom: -15px;
  margin-top: 60px;
  text-transform: capitalize;
}

.credentials {
  color: $clr-accent;
}

.portfolio-item-individual {
  padding: 0 2em 2em;
  max-width: 1000px;
  margin: 0 auto;
}

.portfolio-item-individual p {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.portfolio-header {
  text-align: left;
  padding: 5em 0;
  margin-bottom: 2em;
  background-size: cover;
  background-position: center;
}

.portfolio-title-box {
  background: rgba($clr-accent, 0.75);
  padding: 3em 0 3em 3em;
  color: black;
  width: 70%;

  .portfolio-title {
    font-size: 3.5rem;
    display: inline-block;

    &::after {
      content: '';
      display: block;
      height: 1px;
      margin: 0.5em 0;
      background: white;
      width: 100%;
    }
  }
}

.portfolio-subtitle {
  font-size: 1rem;
}

.portfolio-content {
  text-align: left;
  width: 85%;
  margin: 0 auto;
  padding-bottom: 2em;
}

.portfolio-full-width {
  width: 100vw;
  max-width: initial;
  margin: 2em calc(50% - 50vw);
  height: 20vh;
  object-fit: cover;
}
